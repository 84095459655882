<template>
    <div>
        <div class="field">
            <label class="label">Lat</label>
            <div class="control">
                <input type="text" class="input" v-model="lat">
            </div>
        </div>
        <div class="field">
            <label class="label">Lon</label>
            <div class="control">
                <input class="input" type="text" v-model="lon">
            </div>
        </div>
        <div class="field">
            <label class="label">Radius (meters)</label>
            <div class="control">
                <input class="input" type="text" v-model="radiusInMeters">
            </div>
        </div>
        <div class="field">
            <p class="control">
                <button @click="getAdventures()" class="button is-success">
                View Adventures
                </button>
            </p>
        </div>
        <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
        <div class="container">
            <div v-for="(adventure, index) in adventures" :key="index" class="card">
                <div class="card-content">
                    <p class="title">
                    {{ adventure.name }}
                    </p>
                    <p class="subtitle">
                    {{ adventure.starting_location_name }}
                    </p>
                    <p class="subtitle">
                    {{ adventure.hitMetadata.distance }}
                    </p>
                    <div class="field">
                        <label class="label">Name</label>
                        <div class="control has-icons-left">
                            <input type="text" class="input" v-model="name">
                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>
                        </div>
                    </div>
                    <div class="field">
                        <p class="control">
                            <button @click="createTeam(adventure.adventureId)" class="button is-success">
                            Create a team
                            </button>
                        </p>
                    </div>
                    <p v-if="createATeamMessage" class="help">{{ createATeamMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
    name: 'ViewAdventures',
    data() {
        return {
            lat: 1,
            lon: 1,
            radiusInMeters: 99999999999999999,
            errorMessage: null,
            adventures: [],
            name: null,
            createATeamMessage: null
        }
    },
    methods: {
        getAdventures() {
            this.errorMessage = null
            this.successMessage = null
            const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
            masterFunction({
                methodName: 'get-adventures',
                lat: Number(this.lat),
                lon: Number(this.lon),
                radiusInMeters: Number(this.radiusInMeters)
            }).then(result => {
                console.log(result)
                this.adventures = result.data
            }).catch(err => {
                this.errorMessage = err
                console.log(err)
            })
        },
        createTeam(adventureId) {
            const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
            masterFunction({
                methodName: 'create-team',
                name: this.name,
                adventureId,
                lat: Number(this.lat),
                lon: Number(this.lon)
            }).then(result => {
                console.log(result)
                this.createATeamMessage = result.data
            }).catch(err => {
                // this.errorMessage = err
                console.log(err)
                this.createATeamMessage = err
            })
        }
    }
}
</script>

<template>
  <div class="container">
    <AddAdventure/>
    <ViewAdventures/>
  </div>
</template>

<script>
import AddAdventure from '@/components/AddAdventure'
import ViewAdventures  from '@/components/ViewAdventures'

export default {
  name: 'Home',
  components: {
      AddAdventure,
      ViewAdventures
  }
}
</script>

<template>
    <div>
        <div class="field">
            <label class="label">Adventure JSON</label>
            <div class="control has-icons-left">
                <textarea class="textarea" placeholder="10 lines of textarea" rows="20" v-model="adventureJson"></textarea>
                <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                </span>
            </div>
        </div>
        <div class="field">
            <p class="control">
                <button @click="addAdventure()" class="button is-success">
                Add
                </button>
            </p>
        </div>
        <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
        <p v-if="successMessage" class="help is-success">{{ successMessage }}</p>
    </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
    name: 'AddAdventure',
    data() {
        return {
            adventureJson: null,
            errorMessage: null,
            successMessage: null
        }
    },
    methods: {
        addAdventure() {
            this.errorMessage = null
            this.successMessage = null
            const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
            masterFunction({
                methodName: 'create-adventure',
                adventureJson: this.adventureJson
            }).then(result => {
                console.log(result)
                this.successMessage = result
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>
